.baucua-container {
  width: 1030px;
  height: 570px;
  color: white;
  font-family: Tahoma, "Sanserif";
}
.bc-dice-area {
  position: absolute;
  top: 145px;
  left: 435px;
  width: 135px;
  height: 125px;
  // border: 1px solid red;
}

.bc-countdown {
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  top: 15px;
  left: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  // border: 1px solid red;
}

.bc-chat {
  color: white;
  position: absolute;
  top: -80px;
  left: 253px;
  font-size: 30px;
  z-index: 3;
  width: 460px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.bc-result {
  color: white;
  position: absolute;
  top: 290px;
  left: 283px;
  font-size: 14px;
  scale: 1.2;
  width: 460px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid red;
}

.group-dice-bucket-disc {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  animation: bucket-disc 4s;
  // border: 1px solid red;
}

@keyframes bucket-disc {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  50% {
    transform: translateX(-24px) translateY(-62px) rotate(-20deg);
  }
  53% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  56% {
    transform: translateX(-24px) translateY(-62px) rotate(-20deg);
  }
  65% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  70% {
    transform: translateX(-24px) translateY(-62px) rotate(-20deg);
  }
  75% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  90% {
    transform: translateX(-24px) translateY(-62px) rotate(-20deg);
  }
  95% {
    transform: translateX(-24px) translateY(-62px) rotate(-20deg);
  }
  100% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
}

.bc-aura-effect {
  animation: blink 0.5s infinite;
}

.bc-bucket-in {
  animation: bucket-in 4s forwards;
}

.bc-bucket-out {
  animation: bucket-out 0.5s forwards;
}

@keyframes bucket-in {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  10% {
    transform: translateX(-24px) translateY(-24px) rotate(-10deg);
  }
  30% {
    transform: translateX(-24px) translateY(-24px) rotate(-20deg);
  }
  50% {
    transform: translateX(-124px) translateY(-124px) rotate(-40deg);
  }
  70% {
    transform: translateX(-124px) translateY(-124px) rotate(-40deg);
  }
  100% {
    transform: translateX(-124px) translateY(-124px) rotate(-40deg);
  }
}

@keyframes bucket-out {
  0% {
    transform: translateX(-124px) translateY(-124px) rotate(-40deg);
  }
  100% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
}

// .selected{
//   background: url(../../assets/images/baucua.webp)

// }
