.aura {
  transform-origin: bottom center;
  // animation: aura-shrink 1s ease-in-out infinite; /* Lặp vô hạn */
}

@keyframes aura-shrink {
  0% {
    transform: scale(1) rotate(0deg); /* Kích thước ban đầu */
  }
  50% {
    transform: scale(1.2) rotate(2deg); /* Phóng to lên 1.5 lần */
  }
  100% {
    transform: scale(1) rotate(0deg); /* Quay về kích thước ban đầu */
  }
}

.border-rouned {
  border: 2px solid red;
}

.disc-effect-out {
  animation: moveSemiCircle 1s ease-in-out, holdScale 1s ease 1s forwards;
}

@keyframes moveSemiCircle {
  0% {
    transform: translateX(0) scale(1);
  }
  75% {
    transform: translateX(100px) translateY(50px) scale(0.5);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes holdScale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

.disc-effect-in {
  animation: moveSemiCircle-reverse 1.5s ease-in-out;
}

@keyframes moveSemiCircle-reverse {
  0% {
    transform: scale(0);
  }
  25% {
    transform: translateX(200px) translateY(50px) scale(0.5);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}

.tx-dice-area {
  position: absolute;
  top: 200px;
  left: 400px;
  width: 230px;
  height: 230px;
  // border: 1px solid red;
}

@keyframes roll-dice {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  25% {
    transform: rotateX(360deg) rotateY(180deg);
  }
  50% {
    transform: rotateX(720deg) rotateY(360deg);
  }
  75% {
    transform: rotateX(1080deg) rotateY(540deg);
  }
  100% {
    transform: rotateX(1440deg) rotateY(720deg);
  }
}

.dice-container {
  position: relative;
  max-width: 77px;
  max-height: 77px;
  perspective: 1000px;
}

.dice {
  max-width: 77px;
  max-height: 77px;
  position: absolute;
  transform-style: preserve-3d;
  animation: roll-dice 1s ease-in-out;
}

.dice-rolling-time {
  position: absolute;
  top: 100px;
  left: 0;
  max-width: 50px;
  max-height: 50px;
}

.appearAura {
  animation: spin 3s linear infinite;
}

.begin-replay-img {
  position: absolute;
  top: 470px;
  left: 250px;
  transform: translateX(-50%);
  transform: scale(0.8);
  cursor: pointer;
}

.media-controller{
  position: absolute;
  top:117px;
  right:100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
    // border: 1px solid red;
}

.tx-sound-icon {
  font-size: 25px;
  color: white;
  // border: 1px solid red;
}
.tx-music-icon {
  font-size: 10px;
  color: white;

  // border: 1px solid red;
}

