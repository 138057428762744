.user-page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.user-page-wrapper {
  max-width: 786px;
  background: white;

  height: 100vh;
  width: 100vw;

  // box-shadow: 10px 10px 10px rgba(4, 147, 241, 0.5);
}

.group-icon-userpage {
  display: flex; /* Ensure the container is a flexbox */
  justify-content: flex-end; /* Align items to the right */
  align-items: center; /* Adjust vertical alignment as needed */
  gap: 20px;
  background: rgb(44, 160, 255);
  color: white;
  padding: 10px;
  font-size: 25px;
}

.basic-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: rgb(44, 160, 255);
  color: white;
  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  img {
    flex: 1;
    border: 3px solid rgb(4, 147, 241);
    border-radius: 50%;
    width: 125px;
  }
  .resources {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}

.info-wrapper {
  padding: 10px;
  position: relative;
}

.info-field {
  background: rgb(139, 209, 255);
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  display: flex;
  align-items: flex-start;
  position: relative;
  i {
    color: rgb(4, 147, 241);
  }
  .iconmodal {
    position: absolute;
  }
  color: rgb(3, 64, 105);
  cursor: pointer;
}

.info-field-modal {
  display: flex;
  flex-direction: column;
}

.conect8d-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-money {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .trans-radio {
    width: 20px;
  }
}

.pr-check-icon {
  // border: 1px solid red;
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(40, 167, 1);
  box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.5);
  font-size: 20px;
}

.pr-x-icon {
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(167, 1, 1);
  box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.5);
  font-size: 20px;
  display: flex;
}

.trans-input {
  width: 200px;
  height: 40px;
  margin: 5px 0;
}

.trans1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.trans-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 200px;
  height: 40px;

  .trans-x {
    background: rgb(145, 0, 0);
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
    color: white;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  .trans-check {
    background: rgb(0, 141, 7);
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
    color: white;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
        cursor: pointer;
  }
}
