.modal-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  margin-top: 50px;
  border-radius: 10px;
  margin: auto;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
}

form {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  background-color: #f9f9f9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 400px;
  border-radius: 15px;
  border: 1px solid rgb(182, 187, 189);
  z-index: 1000;
  h2 {
    font-weight: 700;
    color: rgba(0, 34, 184, 0.8);
  }
  .x-icon {
    position: absolute;
    right: 10px;
    font-weight: 700;
    font-size: 20px;
    width: 25px;
    height: 25px;
    background: rgb(182, 1, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
  }
}

input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}
button {
  padding: 10px;
  background-color: rgba(0, 34, 184, 0.8);
  font-weight: 700;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.switch-form {
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-content{form {
    width: 80vw;
  }}
  
}