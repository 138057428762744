.mainpage-container {
    background: url("../assets/images/bgmb.jpg") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100vw;
    z-index: -2;
}

.mainpage-container::before {
    content:"";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,98,197, .6);
    z-index: 0;
}