.xd-countdown {
  position: absolute;
  top: 128px;
  right: 224px;
  font-size: 50px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.bowl-disc {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  animation: bowl-disc 4s;
}

@keyframes bowl-disc {
  0% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  50% {
    transform: translateX(-124px) translateY(-24px) rotate(-20deg);
  }
  53% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  56% {
    transform: translateX(-124px) translateY(-24px) rotate(-20deg);
  }
  65% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  70% {
    transform: translateX(-124px) translateY(-24px) rotate(-20deg);
  }
  75% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
  90% {
    transform: translateX(-124px) translateY(-24px) rotate(-20deg);
  }
  95% {
    transform: translateX(-24px) translateY(-24px) rotate(-3deg);
  }
  100% {
    transform: translateX(0) translateY(0) rotate(0deg);
  }
}

.chipArea {
  position: absolute;
  top: 215px;
  left: 460px;
  width: 85px;
  height: 50px;
  // border: 1px solid red;
}

.disabled {
  opacity: 0.5;
  pointer-events: none; // Không cho phép click
}

.chanIcon,
.leIcon {
  transition: transform 0.3s ease-in-out;
}
.chanIcon:hover,
.leIcon:hover {
  transform: scale(1.2);
}

.xd-4-red,
.xd-3-red,
.xd-3-white,
.xd-4-white {
  position: absolute;
  bottom: 10px;
  width: 150px;
  height: 150px;
}

.xd-4-red {
  left: 128px;
}

.xd-3-red {
  left: 348px;
}

.xd-3-white {
  left: 568px;
}

.xd-4-white {
  right: -53px;
}

.aura-blink {
  animation: aura-blink-effect 0.5s infinite;
}

@keyframes aura-blink-effect {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.sound-icon-xd {
  position: absolute;
  top: 100px;
  right: 280px;
  font-size: 30px;
  color: white;
}

.xd-final-chat {
  img {
    position: absolute;
    bottom: 330px;
    left: 250px;
    transform: rotateY(180deg) !important;
  }
}

.xd-text {
  width: 470px;
  height: 90px;
  position: absolute;
  bottom: 585px;
  left: 320px;
  font-size: 30px;
  font-weight: normal;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: blink 1s infinite;
  // border:1px solid red;
}

.group-data {
  position: absolute;
  top: 0px;
  left: 0px;
  color: white;
  font-size: 28px;
  .player-chan {
    position: absolute;
    top: 120px;
    left: 90px;
    display: flex;
    gap: 10px;
  }
  .money-chan {
    position: absolute;
    top: 285px;
    left: 140px;
    display: flex;
    gap: 10px;
  }
  .player-le {
    position: absolute;
    top: 120px;
    left: 830px;
    display: flex;
    gap: 10px;
  }
  .money-le {
    position: absolute;
    top: 285px;
    left: 700px;
    display: flex;
    gap: 10px;
  }
  .player-4d {
    position: absolute;
    top: 350px;
    left: 70px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .money-4d {
    position: absolute;
    top: 475px;
    left: 110px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .player-3d1t {
    position: absolute;
    top: 350px;
    left: 260px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .money-3d1t {
    position: absolute;
    top: 475px;
    left: 290px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .player-1d3t {
    position: absolute;
    top: 350px;
    left: 435px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .money-1d3t {
    position: absolute;
    top: 475px;
    left: 465px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .player-4t {
    position: absolute;
    top: 350px;
    left: 620px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .money-4t {
    position: absolute;
    top: 475px;
    left: 650px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .player-2d2t {
    position: absolute;
    top: 350px;
    left: 800px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .money-2d2t {
    position: absolute;
    top: 475px;
    left: 830px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
  .xd-round {
    position: absolute;
    top: 115px;
    left: 530px;
    display: flex;
    gap: 10px;
    font-size: 15px;
  }
}
