.button-styles {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  text-shadow: 1px 1px 1px rgb(0, 132, 255),
    /* Đổ bóng viền phía dưới phải */ -1px -1px 1px rgb(0, 132, 255),
    /* Đổ bóng viền phía trên trái */ 1px -1px 1px rgb(0, 132, 255),
    /* Đổ bóng viền phía trên phải */ -1px 1px 1px rgb(0, 132, 255);
  border-radius: 40px;
  .button-text {
    display: flex;
    white-space: nowrap;
  }
}
.button-styles::before {
  content: "";
  position: absolute;
  top: -100px;
  left: 0;
  width: 30px;
  height: 300px;
  border-left: 2px solid white;
  border-right: 10px solid white;
  z-index: 9999;
  animation: shining 5s infinite; /* Áp dụng animation sáng vô hạn */
}

@keyframes shining {
  0% {
    transform: rotate(15deg) translateX(-100px);
  }
  20% {
    transform: rotate(15deg) translateX(280px);
  }
  20.1%,
  100% {
    transform: rotate(15deg) translateX(-100px);
  }
}

.win-img {
  animation: win-effect 5s forwards;
  animation-delay: 2s;
  opacity: 0;
}

@keyframes win-effect {
  0% {
    transform: translateY(290px) scale(0.8);
    opacity: 0;
  }
  90% {
    transform: translateY(0) scale(0.9);
    opacity: 1;
  }
  100% {
    transform: translateY(0) scale(1.2);
    opacity: 0;
  }
}

.ins-content {
  position: absolute;
  top: 90px;
}

.close-icon {
  position: absolute;
  top: -3px;
  left: 610px;
  color: white;
  font-size: 35px;
  cursor: pointer;
}

.intrustion-title {
  position: absolute;
  top: -5px;
  left: 460px;
  color: white;
  font-size: 23px;
  white-space: nowrap;
}
.intrustion-content {
  position: absolute;
  width: 240px;
  top: 55px;
  left: 400px;
  color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

.htp-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

.countdown {
  position: absolute;
  top: -58px;
  left: -55px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
}

.point {
  position: absolute;
  top: -80px;
  left: -79px;
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
}

.tx-text {
  font-family: Tahoma;
  width: 460px;
  height: 88px;
  position: absolute;
  top: -85px;
  left: 315px;
  font-size: 32px;
  font-weight: normal;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: blink 1s infinite;
  // border: 1px solid red;
}

.ld-text {
  color: white;
  position: absolute;
  top: -175px;
  left: 307px;
  font-size: 32px;
  width: 460px;
  height: 90px;
  font-weight: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: blink 1s infinite;
  // border: 1px solid red;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.close-icon-xd {
  position: absolute;
  top: -30px;
  right: 200px;
  color: white;
  font-size: 35px;
  cursor: pointer;
}

.rule-title {
}
