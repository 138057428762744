.wheel-wrapper {
  background: url("../../assets/images/bgmb.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.wheel-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wheel-container-mobile {
  scale: 0.4;
}

.group-wheel-pc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.group-wheel-mb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.group-wheel-canvas {
  position: relative;
}
.wheel-canvas {
  position: absolute;
  top: 58px;
  left: 38px;
  scale: 1.02;
  z-index: 0;
}

.prizes-container {
  position: fixed;
  top: 0px;
  // top: 30%;
  // left: 50%;
  // transform: translateX(-50%) translateY(-50%);
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.form-gift {
  background: url(../../assets/images/prize-info.png);
  background-position: 0px 10px;
  width: 515px;
  height: 320px;
  color: white;
  position: absolute;
  z-index: 1;
  border-radius: 15px;
  // top: 40%;
  // left: 50%;
  transform: translateX(-50%);
}

.form-sign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 95px;
  left: 36px;
  width: 450px;
  height: 200px;
  // border: 1px solid red;
  input {
    padding: 10px;
    margin: 5px;
    width: 350px;
  border: none;
    outline: none;
    background: #ffffff; /* Màu nền sáng để dễ thấy hiệu ứng */
    box-shadow: 
        inset 4px 4px 4px rgba(0, 0, 0, 0.7),  /* Bóng tối đổ vào trong */
        inset -4px -4px 5px rgba(206, 206, 206, 0.8); /* Bóng sáng từ trên trái */
    border-radius: 5px; /* Bo góc nhẹ để hiệu ứng mượt hơn */

  }
}

.lw-sound-icon {
  position: absolute;
  top: 300px;
  left: 24%;
  z-index: 1;
}
