.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: rgba($color: #000000, $alpha: 0.5);
  color: white;
  text-align: center;
  position: fixed;
  top: 300px;
  width: 300px;
  height: 100px;
}

.loading-bar {
  width: 260px;
  height: 42px;
  border: 3px solid rgb(0, 140, 255);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  box-shadow: 5px 5px 5px rgba(0,0,0,.6);
}
.loading-progress {
  background: linear-gradient(90deg, #0051ff, #00eeff);
  font-size: 24px;
  border-radius: 50px;
  font-weight: bold;
  transition: width 0.3s ease-in-out;
  animation: fadeIn 1.5s ease-in-out infinite alternate;
  color: rgb(0, 140, 255);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loading-text {
  position: absolute;
  top: -1px;
  left: 0px;
  width: 255px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.loading-percentage {
  margin-top: 8px;
  font-size: 18px;
  font-weight: bold;
}

