.text-confirm-selected {
    color: white;
    position: absolute;
    width: 460px;
    height: 230px;
    top: -30px;
    left: 970px;
    font-size: 20px;
    overflow: auto;
    // border: 1px solid red;
}

/* Tùy chỉnh thanh cuộn */
.text-confirm-selected::-webkit-scrollbar {
  width: 5px; /* Độ rộng thanh cuộn */
}

.text-confirm-selected::-webkit-scrollbar-track {
  background: #eaeaea; /* Màu nền đường trượt */
  border-radius: 5px;
}

.text-confirm-selected::-webkit-scrollbar-thumb {
  background: #888; /* Màu thanh cuộn */
  border-radius: 5px; /* Bo góc thanh cuộn */
}

.text-confirm-selected::-webkit-scrollbar-thumb:hover {
  background: #555; /* Màu khi di chuột vào thanh cuộn */
}

.text-selected-result {
    color: white;
    position: absolute;
    width: 460px;
    height: 234px;
    top: 75px;
    left: 10px;
    font-size: 20px;
    overflow: auto;
    // border: 1px solid red;
}

/* Tùy chỉnh thanh cuộn */
.text-selected-result::-webkit-scrollbar {
  width: 5px; /* Độ rộng thanh cuộn */
}

.text-selected-result::-webkit-scrollbar-track {
  background: #eaeaea; /* Màu nền đường trượt */
  border-radius: 5px;
}

.text-selected-result::-webkit-scrollbar-thumb {
  background: #888; /* Màu thanh cuộn */
  border-radius: 5px; /* Bo góc thanh cuộn */
}

.text-selected-result::-webkit-scrollbar-thumb:hover {
  background: #555; /* Màu khi di chuột vào thanh cuộn */
}