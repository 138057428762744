.header-container {
  width: 100vw;
  
  height: 80px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .header-video {
    z-index: -1;
    video {
      width: 100%;
    }
  }
  .header-top-pc {
    position: absolute;
    width: 70vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    .top-left {
      img {
        position: absolute;
        left: 100px;
        top: -10px;
      }
    }
  }
  .header-top-mb {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-top: -7px;
    .logo {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -120%;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 50%;
      }
    }
  }

  .group-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .logo-img {
    display: flex;
  }
}

.login-button,
.register-button {
  border: 2px solid rgb(226, 223, 223);
  border-radius: 15px;
  width: 150px;
  height: 50px;
  margin-left: 10px;
  font-family: 'Poppins';
  // background-image: url(../assets/images/buttons.webp); /* Giữ hình nền */
  // background-position: -10px -147px;
  // width: 243px;
  // height: 100px;
  // scale: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  white-space: nowrap;
  // position: relative;
  color: rgb(226, 223, 223); /* Màu fallback nếu browser không hỗ trợ */
  background-color: rgb(9, 48, 121);
}

.login-button:hover,
.register-button:hover {
  color: rgb(6, 74, 201);
  background-color: white;
  transition: .3s ease-in-out;
}

/* Tạo lớp phủ chữ để hiệu ứng RGB */
// .login-button::after,
// .register-button::after {
//   content: attr(data-text);
//   position: absolute;
//   inset: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 42px;
//   font-weight: 700;
//   background-image: linear-gradient(90deg, red, yellow, green, cyan, blue, magenta, red);
//   background-size: 300%;
//   background-clip: text;
//   -webkit-background-clip: text;
//   color: transparent;
//   animation: rgbWave 3s infinite linear;
// }

// @keyframes rgbWave {
//   0% { background-position: 0% 50%; }
//   50% { background-position: 100% 50%; }
//   100% { background-position: 0% 50%; }
// }
.welcome-text {
  color: white;
  font-weight: 700;
  font-size: 24px;
}

.logo-mobile {
  position: absolute;
  top: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
  }
}

.logo-img {
  filter: brightness(120%);
}

@media (max-width: 768px) {
 .header-container {
  height: 110px
 } 
}